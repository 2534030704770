import { sliceLocale } from './sliceLocale'
import { displayDate } from './displayDate'

export const formatTermDate = (
  dateString: string,
  specificity: string,
  language: string,
) => {
  let options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }
  if (specificity === 'year') {
    options = {
      year: 'numeric',
    }
  } else if (specificity === 'month') {
    options = {
      month: 'long',
      year: 'numeric',
    }
  }
  // TODO get right TZ
  return displayDate(dateString, 'UTC', sliceLocale(language), options)
}
