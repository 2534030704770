import { sliceLocale } from './sliceLocale'

export const displayDate = (
  timestamp: string,
  timeZone: string,
  language: string,
  format: Intl.DateTimeFormatOptions,
): string => {
  return new Date(`${timestamp}Z`).toLocaleDateString(sliceLocale(language), {
    ...format,
    timeZone,
  })
}
